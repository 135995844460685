<template>
  <x-dialog :proxy="importDialog">
    <div class="mt-3">
      <h3 style="color: #333">第一步：填写导入数据信息</h3>
      <div class="tip">
        请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除。
      </div>
      <el-button type="text" @click="downTemplate">下载模板</el-button>
    </div>
    <div class="mt-3">
      <h3 style="color: #333">第二步：上传填好的信息表</h3>
      <div class="tip">
        文件后缀名必须为 <b>.xls</b> 或 <b>.xlsx</b>（即 <b>Excel</b> 格式），文件大小不得大于10M，最多支持导入3000条数据。
      </div>

      <el-popover placement="top-start" trigger="hover" popper-class="special-reminder">
        <el-alert title="特别提醒" type="warning" description="导入过程中如发现同名客户，且对该客户数据有更新权限，则更新这条客户数据。" :closable="false"
          show-icon />

        <upload-excel type="text" show-import-count :on-success="excelSuccess" slot="reference" />
      </el-popover>
    </div>
  </x-dialog>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';
import UploadExcel from '@/components/UploadExcel';
import { RegExp as $regExp } from '../../../ui-utils';
import { handleDownload } from '@/utils';
import * as API_Users from '@/api/users';

export default {
  name: 'import-member',
  components: {
    UploadExcel,
    XDialog
  },
  data () {
    return {
      importDialog: $xDialog.create({
        title: '批量导入用户',
        wrapperClass: 'import-member-dialog',
        beforeConfirm: () => this.uploadData(),
      }),
      importNum: 0,
      importData: [],
      importing: false,
      importPercentage: 0,
      templateKey: ['group_name', 'mobile', 'nickname', 'real_name'],
      templateHeader: ['群组', '手机号', '用户名', '姓名'],
      isExistErrorData: false,
    }
  },
  methods: {
    show () {
      this.importNum = 0;
      this.importData = [];
      this.importing = false;
      this.importPercentage = 0;
      this.importDialog.display();
    },
    excelSuccess ({ results }) {
      this.isExistErrorData = true;
      results = JSON.parse(
        this.templateKey.reduce((temp, item, index) => {
          return temp.replace(new RegExp(this.templateHeader[index], 'g'), item)
        }, JSON.stringify(results))
      );

      for (const item of results) {
        if (!item.group_name) {
          this.$message.error('分组名称必填');
          return;
        }

        if (!item.mobile) {
          this.$message.error('手机号必填');
          return;
        }

        if (!$regExp.mobile.test(item.mobile)) {
          this.$message.error('请检查手机号格式');
          return;
        }

        if (!item.nickname) {
          this.$message.error('用户名必填');
          return;
        }
      }
      this.isExistErrorData = false;
      this.importData = results;
      this.importNum = results.length;
    },
    uploadData () {
      return new Promise(resolve => {
        if (!this.importData.length && !this.isExistErrorData) {
          this.$message.error('请导入数据');
          resolve(false);
        } else if (!this.importData.length && this.isExistErrorData) {
          this.$message.error('导入的Excel内可能存在必填项没有填写或存在重复数据，请检查表格确认无误后重新导入');
          resolve(false);
        } else {
          API_Users.importMember({
            member_shop: this.importData,
          }).then((res) => {
            if (res.result === 2) {
              this.falg = false;
              setInterval(() => {
                this.num = 100;
                if (this.num === 100) {
                  this.lag = true;
                }
              }, 2000);
              resolve(true);
              this.$emit('imported');
            } else {
              this.$message.error(res.message);
              resolve(false);
            }
          });
        }
      });
    },
    downTemplate () {
      handleDownload(
        [
          {
            group_name: '',
            mobile: '',
            nickname: '',
            real_name: '',
          },
        ],
        this.templateHeader,
        this.templateKey,
        '用户批量导入模板'
      );
    },
  }
}
</script>

<style lang="scss">
.special-reminder {
  padding: 0;

  .el-alert {
    padding: 16px;
  }
}

.import-member-dialog {
  .tip {
    font-size: 13px;
    color: #666;
  }
}
</style>
