<template>
  <div class="user-list-page">
    <en-table-layout :tableData="tableData.data" @selection-change="handleSelectionChange" :row-key="getRowKeys">
      <template slot="toolbar">
        <div class="col toolbar-title">
          用户管理
        </div>
        <div class="col-auto">
          <el-button type="primary" size="small" @click="addMember()" class="mr-2">
            添加用户
          </el-button>

          <el-dropdown @command="batchOps" size="small">
            <el-button type="primary" size="small" :loading="importLoading">
              批量操作 <i class="el-icon-arrow-down el-icon--right ml-2"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="enabled">批量启用</el-dropdown-item>
              <el-dropdown-item command="disabled">批量禁用</el-dropdown-item>
              <el-dropdown-item command="setGroup">批量设置分组</el-dropdown-item>
              <el-dropdown-item command="import">批量导入用户</el-dropdown-item>
              <el-dropdown-item command="export">批量导出用户</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="col-12 userData">
          <el-row type="flex" class="user-statistics">
            <el-col>
              <p>今日新增</p>
              <span>{{ userStaticData.memberTodayNum ? userStaticData.memberTodayNum : 0 }}人</span>
            </el-col>
            <el-col>
              <p>昨日新增</p>
              <span>{{ userStaticData.memberYesterdayNum ? userStaticData.memberYesterdayNum : 0 }}人</span>
            </el-col>
            <el-col>
              <p>本周新增</p>
              <span>{{ userStaticData.memberWeekNum ? userStaticData.memberWeekNum : 0 }}人</span>
            </el-col>
            <el-col>
              <p>本月新增</p>
              <span>{{ userStaticData.memberMonthNum ? userStaticData.memberMonthNum : 0 }}人</span>
            </el-col>
            <el-col>
              <p>总数</p>
              <span>{{ userStaticData.memberTotalNum ? userStaticData.memberTotalNum : 0 }}人</span>
            </el-col>
            <el-col>
              <p>用户总余额</p>
              <span>{{ userStaticData.userMoneyAll ? userStaticData.userMoneyAll : 0 }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="col-12">
          <div class="row no-gutters align-items-center pt-3">
            <el-form-item label="用户状态">
              <el-select style="width: 80px" v-model="inform.disabled" size="medium" placeholder="请选择"
                class="choose-machine" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option label="正常" value="0"></el-option>
                <el-option label="禁用" value="-1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属分组">
              <el-select v-model="inform.group_id" filterable placeholder="请选择" clearable>
                <el-option v-for="item in groupingOptions" :key="item.group_id" :label="item.group_name"
                  :value="item.group_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户名">
              <el-input size="medium" style="width: 180px" v-model.trim="inform.keyword" placeholder="请输入用户名或真实姓名"
                clearable></el-input>
            </el-form-item>
            <el-form-item label="注册时间">
              <el-date-picker style="width: 260px" v-model="time" size="medium" type="daterange"
                @change="daterangeDateChange" start-placeholder="开始日期" end-placeholder="结束日期"
                :default-time="['00:00:00']" />
            </el-form-item>

            <el-form-item label="用户ID">
              <el-input size="medium" style="width: 120px" v-model.trim="inform.member_id" placeholder="请输入用户ID"
                clearable></el-input>
            </el-form-item>

            <el-form-item label="手机号">
              <el-input size="medium" style="width: 120px" v-model.trim="inform.mobile" placeholder="请输入手机号" clearable />
            </el-form-item>

            <div class="col"></div>

            <el-button @click="seachMember" size="small" type="primary">
              搜索
            </el-button>
          </div>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column type="selection" :reserve-selection="true"></el-table-column>
        <el-table-column label="用户ID" width="80">
          <template slot-scope="scope">
            {{ scope.row.member_id }}
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="用户名" min-width="150">
          <template slot-scope="scope">
            {{ scope.row.nickname }}
          </template>
        </el-table-column>
        <el-table-column prop="real_name" label="姓名" min-width="150">
          <template slot-scope="scope">
            {{ scope.row.real_name }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" width="110">
          <template slot-scope="scope">
            {{ scope.row.mobile }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="注册时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="enable_money" label="账户余额" width="90">
          <template slot-scope="scope">
            {{ scope.row.enable_money }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="所属分组" width="110">
          <template slot-scope="scope">
            {{ scope.row.group_name || '——' }}
          </template>
        </el-table-column>
        <el-table-column prop="disabled" label="状态" width="90">
          <template slot-scope="scope">
            {{ scope.row.disabled === 0 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="210px">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="addMember(scope.row)">
              编辑
            </el-button>
            <el-button size="mini" type="primary" plain @click="handleEdit(scope.$index, scope.row)">
              详情
            </el-button>
            <el-button size="mini" type="danger" plain @click="handleDelete(scope.$index, scope.row)"
              v-if="scope.row.disabled === 0">
              禁用
            </el-button>
            <el-button size="mini" type="primary" plain @click="reinstatement(scope.$index, scope.row)"
              v-if="scope.row.disabled !== 0">
              启用
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="inform.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="inform.page_size" :total="total" />
    </en-table-layout>

    <add-member ref="addMember" @refresh="getList" />
    <import-member ref="importMember" @imported="handleCurrentChange(1)" />
  </div>
</template>

<script>
import * as API_Users from '@/api/users';
import AddMember from './addmember';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import ImportMember from '@/views/users/ImportMember';
import { handleDownload, mergeSkuList } from "@/utils";
import { Foundation } from "@/../ui-utils";

export default {
  components: {
    ImportMember,
    AddMember,
    EnTableLayout,
  },
  data () {
    return {
      groupingOptions: [],//所属分组
      importLoading: false,
      time: '',
      rules: {
        nickname: [
          {
            required: true,
            message: '请输入用户名称',
            trigger: 'blur'
          },
        ],
        member_id: [
          {
            required: true,
            message: '请输入用户ID',
            trigger: 'blur'
          },
        ],
        mobile: [
          {
            required: true,
            message: '请输入电话号码',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '长度在11个字符',
            trigger: 'blur'
          },
        ],
        disabled: [
          {
            required: true,
            message: '请选择区域',
            trigger: 'change'
          },
        ],
      },
      num: 20,
      total: 0,
      formInline: {
        user: '',
        region: '',
      },
      inform: {
        keyword: '',
        nickname: '',
        page_size: 20,
        page_no: 1,
        disabled: '',
        mobile: '',
        member_id: '',
      },
      params: [],
      page: {},
      tableData: {},
      multipleSelection: [],
      isError: false,
      importData: [],
      importNum: 0,
      userStaticData: {}
    };
  },
  created () {
    this.getList();
    API_Users.getUserGroups().then(res => {
      this.groupingOptions = [
        {
          group_id: '',
          group_name: '全部'
        },
        {
          group_id: 0,
          group_name: '暂无'
        },
        ...res
      ]
    });
  },
  methods: {
    getRowKeys (row) {
      return row.member_id
    },
    batchOps (ops) {
      switch (ops) {
        case 'enabled':
          return this.reinstatement();
        case 'disabled':
          return this.onClose();
        case 'setGroup':
          return this.setMemberGroup();
        case 'import':
          return this.importMore();
        case 'export':
          return this.exportMore();
      }
    },
    bsearch () {
      this.abcd = false;
    },
    daterangeDateChange (val) {
      this.inform.start_time = val ? parseInt(val[0] / 1000) : '';
      this.inform.end_time = val ? parseInt(val[1] / 1000) + 86399 : '';
    },
    //提交
    submitForm () {
      this.getList();
    },
    //重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    importMore () {
      this.$refs.importMember.show();
    },
    exportMore (page_no) {
      this.importLoading = true;
      const tHeaders = [
        "用户ID",
        "用户名",
        "姓名",
        "手机号",
        "注册时间",
        "账户余额",
        "所属分组",
        "状态",
      ];
      const filterVals = [
        "member_id",
        "nickname",
        "real_name",
        "mobile",
        "create_time",
        "enable_money",
        "group_name",
        "disabled",
      ];
      let params = { ...this.inform };
      page_no && (params.page_no = 1);
      params.is_salesman = 0;
      params.page_size = 9999;
      console.log(params)
      API_Users.getMemberList(params).then((res) => {
        let data = res.data;
        console.log(res)
        data.forEach(item => {
          item.member_id = item.member_id
          item.nickname = item.nickname
          item.real_name = item.real_name
          item.mobile = item.mobile
          item.create_time = Foundation.unixToDate(item.create_time)
          item.enable_money = item.enable_money
          item.group_name = item.group_name || "—"
          item.disabled = item.disabled === 0 ? "正常" : "禁用"
        });
        handleDownload(data, tHeaders, filterVals, "用户列表");
        this.importLoading = false;
      });
    },
    clearRes () {
      this.inform.keyword = '';
      this.inform.member_id = '';
      this.inform.mobile = '';
      this.time = '';
      this.inform.disabled = ''; //
      this.inform.start_time = '';
      this.inform.end_time = '';
      this.getList();
    },
    seachMember () {
      this.getList(1);
    },
    getList (page_no) {
      let params = this.inform;
      page_no && (params.page_no = 1);
      params.is_salesman = 0;
      API_Users.getMemberList(params).then((res) => {
        this.total = res.data_total;
        this.page_size = res.page_size;
        this.page_no = res.page_no;
        this.tableData = res;
      });
      API_Users.getUserStaticData().then(res => {
        this.userStaticData = res
      })
    },
    setMemberGroup () {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请先选择用户');
      } else {
        this.$refs.addMember.show({
          num: 4,
          ids: this.params
        });
      }
    },
    addMember (row) {
      if (row) {
        this.$refs.addMember.show({ num: 1, ...row });
      } else {
        let params = {
          nickname: '',
          mobile: '',
          enable_money: '',
          group_id: '',
          disabled: 0
        }
        this.$refs.addMember.show({ num: 1, ...params });
      }
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange (val) {
      this.params = val.map((item, index) => {
        return item.member_id;
      });
      this.multipleSelection = val;
    },
    onClose () {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请先选择用户');
      } else {
        if (this.params) {
          console.log(this.params);
          API_Users.closeMember(this.params).then((res) => {
            this.getList();
          });
        }
      }
    },
    reinstatement (index, row) {
      if (row) {
        API_Users.reinstatement(row.member_id).then((res) => {
          this.getList();
        });
      } else {
        if (this.multipleSelection.length === 0) {
          this.$message.error('请先选择用户');
        } else {
          if (this.params) {
            API_Users.reinstatement(this.params).then((res) => {
              this.getList();
            });
          }
        }
      }
    },
    handleEdit (index, row) {
      this.$router.push({
        path: '/users/user-msg',
        query: { id: row.member_id },
      });
    },
    handleDelete (index, row) {
      this.$confirm('是否要禁用此用户?', '提示', {
        type: 'warning',
      }).then(() => {
        API_Users.deleteMember(row.member_id).then((res) => {
          this.getList();
          this.$message.success('禁用成功');
        });
      });
    },
    handleSizeChange (val) {
      this.inform.page_size = val;
      this.getList();
    },
    handleCurrentChange (val) {
      this.inform.page_no = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.userData {
  margin-top: 5px;

  .user-statistics {
    background-color: #FAFAFA;
    padding: 5px 10px;

    /deep/ p {
      font-size: 18px;
    }

    /deep/ span {
      color: #00dd9b;
      font-size: 18px;
      font-weight: normal;
    }
  }
}

.importok {
  h1 {}
}

.moreUser {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -300px;
  margin-left: -375px;
  background-color: #fff;
  width: 750px;
  height: 600px;
}

.domnload1 {
  cursor: pointer;
  color: #0079fe;
}

.domnload1:hover {
  color: red;
}

.shadow {
  position: absolute;
  z-index: 9999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 1000px;
}

.xleft {
  cursor: pointer;
  float: right;
}

.first {
  font-size: 14px;
  padding: 0px 20px 0px 20px;
}

.secend h2 {
  text-align: center;
  float: left;
  width: 50%;
}

.threeone {
  margin: 20px 10px;
  border: 1px solid rgba(233, 233, 233, 1);
  padding: 18px 0px 10px 120px;
}

.threetwo {
  margin: 20px 10px;
  background-color: rgb(255, 235, 204);
  padding: 15px 20px 15px 60px;
}

.btn2 {
  padding-right: 10px;
  text-align: end;
}

.userAdimin {
  position: relative;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 5px;
}

.demo-ruleForm1 {
  z-index: 2000;
  background-color: #fff;
  position: absolute;
  top: 60px;
  left: 0;
  width: 300px;
  padding: 20px;
}

/deep/ .el-form-item__label {
  font-size: 12px;
}

/deep/ .el-input__inner {
  font-size: 12px;
}

/deep/ .el-range-input {
  font-size: 12px;
}

/deep/ .el-date-editor--datetimerange.el-input__inner {
  width: 168px;
}

.btn33 /deep/ .el-form-item__content {
  margin-left: 0px !important;
}

.posi {
  z-index: 99;
  position: absolute;
  left: 85px;
  top: 100px;
}

.search {
  padding-top: 10px;
}

/*
.fenye .block .el-pagination {
  padding: 10px 0px;
  text-align: center;
}


.search .el-form-item__content {
  width: 145px;
}
.search .el-form-item__content .el-input__inner {
  height: 26px;
}
/* .search .el-button--primary {
  height: 21px;
} */
.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/* .search .btn3 .el-form-item__content {
  width: 400px;
} */
/* .search .el-button {
  line-height: 0;
} */
/* .search .el-form {
  margin-bottom: 20px;
}
.tableList .is-leaf {
  text-align: center;
}
.tableList .el-table__body .el-table__row td .cell {
  text-align: center;
}
.tableList .el-table_1_column_1 .cell .el-checkbox__inner {
  background-color: #e6ecf7;
} */
</style>
