<template>
  <x-dialog :proxy="addDialog" class="addkuang">
    <div class="mt-3"></div>

    <el-form class="form1" v-if="sizeForm.num === 1" ref="form" :model="sizeForm" :rules="editRules" label-width="80px">

      <el-form-item prop="nickname" label="用户姓名">
        <el-input class="ipt" v-model="sizeForm.nickname"></el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="用户电话">
        <el-input class="ipt" :disabled="!!sizeForm.member_id" v-model.number="sizeForm.mobile"></el-input>
      </el-form-item>
      <el-form-item v-if="showMoney" prop="enable_money" label="账户余额">
        <el-input class="ipt" :disabled="sizeForm.num == 1 && sizeForm.nickname"
          v-model="sizeForm.enable_money"></el-input>
      </el-form-item>

      <el-form-item label="用户状态">
        <el-radio-group v-model="sizeForm.disabled">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="-1">禁用</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item class="zt" label="分组">
        <el-select v-model="sizeForm.group_id" size="medium" placeholder="请设置用户分组">
          <el-option v-for="(item, index) in moremsg" :key="index" :label="item.group_name"
            :value="item.group_id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-form v-if="sizeForm.num === 4" class="form2" ref="form" :model="sizeForm">
      <el-form-item label="分组">
        <el-select v-model="sizeForm.group_id" size="medium" style="width: 300px" placeholder="请设置用户分组">
          <el-option v-for="(item, index) in moremsg" :key="index" :label="item.group_name"
            :value="item.group_id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </x-dialog>
</template>

<script>
import * as API_Users from '@/api/users';
import { RegExp } from '@/../ui-utils';
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';

export default {
  name: 'add-member',
  components: { XDialog },
  data () {
    return {
      showMoney: true,//是否显示余额
      addDialog: $xDialog.create({
        width: '450px',
        beforeConfirm: () => {
          if (this.sizeForm.num === 1) {
            return this.onSubmit();
          } else {
            return this.changGroup();
          }
        }
      }),
      sizeForm: {
        nickname: '',
        mobile: '',
        enable_money: '',
        group_id: '',
        disabled: 0
      },
      flag: false,
      moremsg: [],
      // 编辑 表单规则
      editRules: {
        nickname: [
          {
            required: true,
            message: '请输入用户姓名',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入用户姓名'));
              } else {
                callback();
              }
            }
          }
        ],
        enable_money: [
          {
            required: true,
            message: '请输入账户余额',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!value && value !== 0) {
                callback(new Error('请输入账户余额'));
              } else if (value < 0) {
                callback(new Error('账户余额必须大于等于0'));
              } else if (value > 999999) {
                callback(new Error('账户余额必须小于等于999999'));
              } else if (!RegExp.money.test(value)) {
                callback(new Error('请输入正确的面值金额'));
              } else {
                callback();
              }
            }
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入用户电话',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入用户电话'));
              } else if (!RegExp.mobile.test(value)) {
                callback(new Error('手机格式有误'));
              } else {
                callback();
              }
            }
          }
        ]
      }
    };
  },
  methods: {
    show (params) {
      this.sizeForm = params;

      API_Users.getUserGroups().then(res => {
        this.moremsg = {
          instmsg: {
            group_id: 0,
            group_name: '暂无'
          },
          ...res,
        };

        let title;

        if (params.num === 1) {
          title = params.nickname ? '编辑用户' : '添加用户';
          this.showMoney = params.nickname;
        } else {
          title = '设置用户分组';
        }

        this.addDialog.display({
          title,
        });
      });
    },
    onSubmit () {
      return new Promise(resolve => {
        this.$refs['form'].validate(valid => {
          if (valid) {
            if (this.sizeForm.member_id) {
              API_Users.changeMember(this.sizeForm.member_id, this.sizeForm).then(res => {
                this.$emit('refresh', res);
                resolve(true);
              });
            } else {
              API_Users.addMember(this.sizeForm).then(res => {
                this.$emit('refresh', res);
                resolve(true);
              });
            }
          } else {
            this.$message.error('表单填写有误，请检查！');
            resolve(false);
          }
        });
      });
    },
    changGroup () {
      return new Promise(resolve => {
        API_Users.changeGroup(this.sizeForm.ids, {
          group_id: this.sizeForm.group_id,
        }).then(res => {
          resolve(true);
        }).catch(() => {
          resolve(false);
        });
      });
    },
    cancel () {
      this.$emit('fromson', this.flag);
    }
  }
};
</script>

<style scoped lang="scss"></style>
